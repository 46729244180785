// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Home from './components/Home';
import Navbar from './components/Navbar'; // Main app bottom navbar
import ProfileSetup from './components/ProfileSetup';
import Stars from './components/Stars';
import Messages from './components/Messages';
import Profile from './components/Profile';
import Upload from './components/Upload';
import Search from './components/Search';
import TopBar from './components/TopBar'; // TopBar for all pages except Splash
import './App.css';
import AboutUs from './components/AboutUs';
import Mission from './components/Mission';
import Contact from './components/Contact/index'
import Splash from './pages'; // Splash page import
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute
import AuthRoute from './components/AuthRoute'; // Import AuthRoute
import Discography from './components/Discography';
import Settings from './components/Settings';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import AudioPlayer from './components/AudioPlayer'; // Import the custom AudioPlayer
import OtherUserDiscography from './components/OtherUserDiscography';
import axios from 'axios';
import Success from './pages/Success';
import Cancel from './pages/Cancel';

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(null); // State for the current track
  const [autoPlay, setAutoPlay] = useState(false); // State for autoplay

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Function to handle track selection from any page and increment play count
  const handleTrackClick = (track) => {
    setCurrentTrack(track);
    setAutoPlay(true);
  };

  if (loading) {
    return <div>Loading...</div>; // You can add a loading spinner here if needed
  }

  return (
    <Router>
      <div className="app-container">
        <Routes>
          {/* Splash page route */}
          <Route path="/" element={<Splash />} />
          <Route path="/profile-setup" element={<ProfileSetup />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />

          {/* Auth Routes - Sign In/Sign Up - Protected from logged-in users */}
          <Route element={<AuthRoute />}>
            <Route element={<LayoutWithTopBar />}>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
            </Route>
          </Route>

          {/* Protected Routes (only accessible if logged in) */}
          <Route element={<ProtectedRoute />}>
            <Route element={<LayoutWithNavbar handleTrackClick={handleTrackClick} />}>
              <Route path="/home" element={<Home handleTrackClick={handleTrackClick} />} />
              <Route path="/stars" element={<Stars handleTrackClick={handleTrackClick} />} />
              <Route path="/messages" element={<Messages />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/my-discography" element={<Discography handleTrackClick={handleTrackClick} />} />
              <Route path="/discography/:id" element={<OtherUserDiscography handleTrackClick={handleTrackClick}/>} />
              <Route path="/upload" element={<Upload />} />
              <Route path="/search" element={<Search handleTrackClick={handleTrackClick} />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />
            </Route>
          </Route>
        </Routes>
        {/* Render the AudioPlayer at the top level so it persists across page changes */}
        {currentTrack && <AudioPlayer track={currentTrack} autoPlay={autoPlay} />}
      </div>
    </Router>
  );
}

// Layout with TopBar for login/signup/profile setup pages
function LayoutWithTopBar() {
  return (
    <>
      <TopBar />
      <Outlet />
    </>
  );
}

// Layout with the main app's TopBar and bottom Navbar
function LayoutWithNavbar({ handleTrackClick }) {
  return (
    <>
      <TopBar />
      <Outlet context={{ handleTrackClick }} />
      <Navbar />
    </>
  );
}

export default App;
